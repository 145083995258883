<template>
    <el-select placeholder="品牌" v-model="code" filterable v-bind="$attrs" v-on="$listeners" style="width: 180px">
        <el-option label="请选择" value="" />
        <el-option v-for="bd in brands" :label="bd.name" :value="bd.code" :key="bd.code" />
    </el-select>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'EfBrand',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            code: '',
            brands: [],
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.code = n;
            },
            immediate: true,
        },
        code: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
    mounted() {
        UrlUtils.GoodsBrands(this, (rst) => {
            this.brands = rst;
        });
    },
};
</script>
