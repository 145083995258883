<template>
    <el-select placeholder="类目" v-model="code" filterable style="width: 180px">
        <el-option label="请选择" value="" />
        <el-option-group v-for="group in categories" :label="group.name" :key="group.id">
            <el-option
                v-for="category in group.sub"
                :label="category.name"
                :value="category.code"
                :key="category.code"
            />
        </el-option-group>
    </el-select>
</template>

<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'EfCategory',
    model: {
        prop: 'selected',
        event: 'change',
    },
    props: {
        selected: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            code: '',
            categories: [],
        };
    },
    watch: {
        selected: {
            handler(n, o) {
                //假设：null与''等价
                if (n == null) {
                    n = '';
                }
                this.code = n;
            },
            immediate: true,
        },
        code: {
            handler(n, o) {
                this.$emit('change', n);
            },
            immediate: true,
        },
    },
    mounted() {
        UrlUtils.GroupedGoodsCategories(this, (rst) => {
            this.categories = rst;
        });
    },
};
</script>
