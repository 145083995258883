<template>
    <div>
        <el-dialog title="变更历史" :visible.sync="dialogTableVisible" @open="handleQuery" width="80%">
            <el-table border stripe :data="historyData" size="mini" :highlight-current-row="true" max-height="500">
                <el-table-column label="序号" type="index" align="center" fixed="left" />
                <el-table-column property="updater" label="变更人" width="120" />
                <el-table-column property="updateTime" label="变更日期" width="135" />
                <el-table-column property="name" label="物品名称" min-width="150" />
                <el-table-column property="bars" label="条码" width="150">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column property="specs" label="规格" width="150" />
                <el-table-column property="type" label="商品类型" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="category" label="类目" width="120" />
                <el-table-column property="brand" label="品牌" width="120" />
                <el-table-column property="unit" label="单位(小)" width="100" />
                <el-table-column property="expirationDate" label="保质期" width="100" />
            </el-table>
            <div slot="footer">
                <el-button type="primary" @click="dialogTableVisible = false" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'SkuHistory',
    data() {
        return {
            dialogTableVisible: false,
            form: {
                bizCode: '',
            },
            historyData: [],
        };
    },
    methods: {
        async handleQuery() {
            this.historyData = [];
            const rst = await this.$efApi.skuChangeApi.page(this.form);
            this.historyData = rst.data;
        },
        show(code) {
            if (code == null || code === '') {
                this.$alert('未选择记录');
                return;
            }
            this.dialogTableVisible = true;
            this.form.bizCode = code;
        },
    },
    filters: {
        bar(bars) {
            if (bars && bars.length) {
                return bars.join('/');
            }
            return bars;
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            return type ? '非标品' : '标品';
        },
    },
};
</script>
